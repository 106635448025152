import {
  TableCategory,
  TableHeaders,
  CellContentType
} from '@/shared/components/table/models';

export const PerdiemHeaders: TableHeaders[] = [
  {
    key: 'division',
    label: 'DIV',
    sortable: true
  },
  {
    key: 'containerNumber',
    label: 'CONT #',
    sortable: true
  },
  {
    key: 'shippingLine',
    label: 'SSL',
    sortable: true
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true
  },
  {
    key: 'orderNumber',
    label: 'WO #',
    sortable: true
  },
  {
    key: 'fromReturnFreeDay',
    label: 'From (RFD)',
    sortable: true,
    disabled: true,
    content: { type: CellContentType.Date }
  },
  {
    key: 'bookingNumber',
    label: 'Booking #',
    sortable: true,
    disabled: true,
    content: { type: CellContentType.Date }
  },
  {
    key: 'st',
    label: 'S/T',
    sortable: true,
    disabled: true,
    content: { type: CellContentType.Date }
  },
  {
    key: 'toReturnFreeDay',
    label: 'Tо',
    sortable: true,
    disabled: true,
    content: { type: CellContentType.Date }
  },
  {
    key: 'diff',
    label: 'Diff',
    sortable: true
  },
  {
    key: 'inv',
    label: 'INV #',
    sortable: true
  },
  {
    key: 'invDate',
    label: 'INV Date',
    sortable: true,
    disabled: true,
    content: { type: CellContentType.Date }
  },
  {
    key: 'from',
    label: 'INV From',
    sortable: true,
    disabled: true,
    content: { type: CellContentType.Date }
  },
  {
    key: 'to',
    label: 'INV Tо',
    sortable: true,
    disabled: true,
    content: { type: CellContentType.Date }
  },
  {
    key: 'difference',
    label: 'Diff',
    sortable: true
  },
  {
    key: 'invAmount',
    label: 'AMT',
    sortable: true
  },
  {
    key: 'invoice',
    label: 'Invoice',
    sortable: true,
    content: {
      type: CellContentType.Button,
      label: 'Invoice'
    }
  }
];
