import {
  TableHeaders,
  CellContentType
} from '@/shared/components/table/models';

export const QbApArHeaders: TableHeaders[] = [
  {
    key: 'detail',
    label: `Detail`,
    sortable: true,
    content: {
      type: CellContentType.Button,
      label: 'Detail'
    }
  },
  {
    key: 'accType',
    label: `Type`,
    sortable: true
  },
  {
    key: 'status',
    label: `Status`,
    sortable: true
  },
  {
    key: 'orderNumber',
    label: `WO #`,
    sortable: true
  },
  {
    key: 'containerNumber',
    label: `CONT #`,
    sortable: true
  },
  {
    key: 'date',
    label: `Date`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'driverName',
    label: `VEN / DRV`,
    sortable: true
  },
  {
    key: 'driverCode',
    label: `ID`,
    sortable: true
  },
  {
    key: 'name',
    label: `ACCT CODE NAME`,
    sortable: true
  },
  {
    key: 'code',
    label: `ACCT CODE`,
    sortable: true
  },
  {
    key: 'rate',
    label: `Rate`,
    sortable: true
  },
  {
    key: 'quantity',
    label: `QTY`,
    sortable: true
  },
  {
    key: 'amount',
    label: `AMT`,
    sortable: true,
    getClass: data => (data.hasDifferencesWithQB ? 'red bold' : '')
  },
  {
    key: 'ck',
    label: `CK`,
    content: {
      type: CellContentType.Checkbox
    }
  },
  {
    key: 'invoiceNumber',
    label: `INV #`,
    sortable: true
  },
  {
    key: 'qbId',
    label: `QB ID`,
    sortable: true
  },
  {
    key: 'invoiceAmount',
    label: `INV AMT`,
    sortable: true,
    getClass: data => (data.hasDifferencesWithQB ? 'red bold' : '')
  },
  {
    key: 'paidReceivedAmount',
    label: `PAID/RECEIVED AMT`,
    sortable: true
  },
  {
    key: 'expirationDate',
    label: `EXP`,
    sortable: true
  }
];
