import {
  TableHeaders,
  CellContentType
} from '@/shared/components/table/models';

export const ChassisHeaders: TableHeaders[] = [
  {
    key: 'no',
    label: 'NO',
    sortable: true
  },
  {
    key: 'division',
    label: 'DIV',
    sortable: true
  },
  {
    key: 'pool',
    label: 'POOL',
    sortable: true
  },
  {
    key: 'chassis',
    label: 'Chassis #',
    sortable: true
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true
  },
  {
    key: 'orderNumberFrom',
    label: 'WO(From)',
    sortable: true
  },
  {
    key: 'from',
    label: 'From',
    sortable: true
  },
  {
    key: 'orderNumberTo',
    label: 'WO(To)',
    sortable: true
  },
  {
    key: 'to',
    label: 'To',
    sortable: true
  },
  {
    key: 'ngl',
    label: 'NGL USED',
    sortable: true,
    disabled: true,
    content: { type: CellContentType.Date }
  },
  {
    key: 'inv',
    label: 'INV #',
    sortable: true
  },
  {
    key: 'invDate',
    label: 'INV Date',
    sortable: true,
    disabled: true,
    content: { type: CellContentType.Date }
  },
  {
    key: 'containerNumber',
    label: 'CONT #',
    sortable: true,
    disabled: true,
    content: { type: CellContentType.Date }
  },
  {
    key: 'invFrom',
    label: 'From',
    sortable: true,
    disabled: true,
    content: { type: CellContentType.Date }
  },
  {
    key: 'invTo',
    label: 'To',
    sortable: true,
    disabled: true,
    content: { type: CellContentType.Date }
  },
  {
    key: 'invDays',
    label: 'Days',
    sortable: true
  },
  {
    key: 'invAmount',
    label: 'AMT',
    sortable: true
  },
  {
    key: 'diff',
    label: 'Difference',
    sortable: true
  },
  {
    key: 'invoice',
    label: 'Invoice',
    sortable: true,
    content: {
      type: CellContentType.Button,
      label: 'Invoice'
    }
  },
  {
    key: 'save',
    label: 'Save',
    sortable: true,
    content: {
      type: CellContentType.Button,
      label: 'Save'
    }
  }
];
