


































































import { Component, Vue } from 'vue-property-decorator';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { TableHeaders } from '@/shared/components/table/models';

import { AccountingModule } from '@/store/index';
import { AccountingTypes } from '../models/accounting.model';

import QbApArFilters from './QbApArFilters/QbApArFilters.vue';
import { QbApArHeaders } from './models/QbAparHeaders';

import WoBaseModal from '@/pages/Main/components/WoBaseModal.vue';
import {
  FilterConditions,
  FilterInput
} from '@/shared/services/filter/filter.service';
import { ToastHelper } from '@/utils/toast.util';

@Component({
  components: { TmsTable, QbApArFilters, WoBaseModal }
})
export default class QbApAr extends Vue {
  accountingModule = AccountingModule;
  columns: TableHeaders[] = QbApArHeaders;

  selectedWo = null;

  handlerModal = false;
  innerHandlerMessage = null;

  typeFilter = new FilterInput({
    condition: FilterConditions.Equal,
    search: null,
    field: 'accType'
  });

  selectedType = null;

  data = [];

  selectedItems = [];
  canSendToQuickBook = true;

  get isLastPage(): boolean {
    return !this.accountingModule.meta.lastKey;
  }

  constructor() {
    super();
  }

  action(type: { key: string; data: any }) {
    const actionsList = {
      detail: this.openDetail
    };

    actionsList[type.key](type.data);
  }

  async search() {
    this.selectedType = this.typeFilter.search;

    this.data = await AccountingModule.search({
      acctType: AccountingTypes[this.$route.params.type]
    });

    // Clean selected items after the search
    this.selectedItems = [];
    // this.setCKStatus();
  }

  onInputChange({ field, data }) {
    switch (field) {
      case 'ck':
        this.handleItemCheck(data);
        break;
    }
  }

  handleItemCheck(data) {
    const index = this.selectedItems.findIndex(i => i.id === data.id);
    const item = this.data.find(i => i.id === data.id);
    if (index > -1) {
      item.ck = false;
      this.selectedItems.splice(index, 1);
    } else {
      item.ck = true;
      this.selectedItems.push(data);
      if (this.typeFilter.search === 'AR') {
        this.checkTheSameARWithInvoiceNumberNumber(data);
      }
    }

    if (!this.typeFilter.search) {
      return;
    }

    this.typeFilter.search === 'AP' ? this.checkAP() : this.checkAR();
  }

  isMatchQBDisabled() {
    return (
      !this.selectedItems.length ||
      this.selectedItems.some(
        i => i.status === 'ERROR' || i.status === 'NOT_SENT'
      )
    );
  }

  isQuickBookDisabled() {
    return (
      !this.selectedItems.length ||
      this.selectedItems.some(i => i.status === 'LOCKED')
    );
  }

  checkTheSameARWithInvoiceNumberNumber(data) {
    this.data.forEach(i => {
      if (
        i.orderNumber === data.orderNumber &&
        i.invoiceNumber === data.invoiceNumber &&
        i.id !== data.id
      ) {
        i.ck = true;
        this.selectedItems.push(i);
      }
    });
  }

  checkAP() {
    const locked = this.selectedItems.some(i => i.status === 'LOCKED');

    this.canSendToQuickBook = !locked;
    if (locked) {
      ToastHelper.show(
        'Error',
        'Some items are already LOCKED',
        5000,
        'danger'
      );
    }
  }

  checkAR() {
    const wos = {};

    this.selectedItems.forEach(i => {
      if (['NOT_SENT', 'ERROR', 'ACCEPTED'].indexOf(i.status) > -1) {
        const key = `${i.orderNumber}/${i.billTo}`;
        if (wos[key]) {
          wos[key].push(i);
        } else {
          wos[key] = [i];
        }
      }
    });

    let totalItemsToCombine = 0;
    Object.keys(wos).forEach(key => {
      if (wos[key].length > 1) {
        totalItemsToCombine = totalItemsToCombine + wos[key].length;
      }
    });

    if (totalItemsToCombine) {
      this.innerHandlerMessage = `There is a total of ${totalItemsToCombine} item(s) that will be combined.`;
      this.handlerModal = true;
    }
  }

  openDetail(data) {
    this.selectedWo = data;
    this.$bvModal.show('wo-base-modal');
  }

  setCKStatus() {
    const ck = this.columns.find(i => i.key === 'ck');
    ck.disabled = this.typeFilter.search === null;
  }

  async quickBooks() {
    await this.accountingModule.QB(
      this.selectedItems.map(({ orderNumber, accType, id, category }) => ({
        orderNumber,
        accType,
        id,
        category
      }))
    );
    this.search();
  }

  async matchQB() {
    await this.accountingModule.matchQB(
      this.selectedItems.map(({ orderNumber, accType }) => ({
        orderNumber,
        accType
      }))
    );
    this.search();
  }
}
