





















import { Component, Vue } from 'vue-property-decorator';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { TableHeaders } from '@/shared/components/table/models';

import { AccountingModule } from '@/store/index';

import ChassisFilters from './ChassisFilters/ChassisFilters.vue';
import { ChassisHeaders } from './models/ChassisHeaders';
import Loading from '@/shared/components/Loading.vue';
import { AccountingTypes } from '../models/accounting.model';
import { ToastHelper } from '@/utils/toast.util';

Component.registerHooks(['validations']);

@Component({
  components: { TmsTable, ChassisFilters, Loading }
})
export default class Chassis extends Vue {
  accountingModule = AccountingModule;
  columns: TableHeaders[] = ChassisHeaders;

  get isLastPage(): boolean {
    return !this.accountingModule.meta.lastKey;
  }

  constructor() {
    super();
  }

  get rows() {
    const a = [];
    for (let index = 0; index < 16; index++) {
      a.push({
        category: `caategory${index}`,
        from: '2021-04-05',
        to: '2021-08-03',
        invDate: '2021-08-03',
        invFrom: '2021-08-03',
        invTo: '2021-08-03',
        orderNumberFrom: `aaaa${index}`,
        billTo: `bill to ${index}`,
        containerNumber: `number124 ${index}`
      });
    }
    return a;
  }

  validate(str) {
    if (!str || str.trim() == '') {
      return false;
    } else {
      return null;
    }
  }

  testList = [
    {
      text: 'I210701S001-1',
      value: { orderNumber: 'I210707S001-1', date: '16.03.21' }
    },
    {
      text: 'I210701S02',
      value: { orderNumber: 'I210701S02', date: '28.04.21' }
    }
  ];

  show() {
    console.log('show');
    this.orderNumber = this.tt['orderNumber'];
  }

  save(item) {
    console.log(item);
  }

  orderNumber = null;

  tt = {};
}
