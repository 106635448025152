















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Fragment } from 'vue-fragment';
import { FilterInput } from '@/shared/services/filter/filter.service';

@Component({
  components: { Fragment }
})
export default class StatusFilter extends Vue {
  @Prop() status: FilterInput<string>;

  to = null;
  from = null;
  maxDate = null;

  created() {
    this.status.search = 'notSent';
  }
}
