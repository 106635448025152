
















import { Component, Vue } from 'vue-property-decorator';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { TableHeaders } from '@/shared/components/table/models';

import { AccountingModule } from '@/store/index';
import { AccountingTypes } from '../models/accounting.model';

import PerdiemFilters from './PerdiemFilters/PerdiemFilters.vue';
import { PerdiemHeaders } from './models/PerdiemHeaders';
import { ToastHelper } from '@/utils/toast.util';

@Component({
  components: { TmsTable, PerdiemFilters }
})
export default class Perdiem extends Vue {
  accountingModule = AccountingModule;
  columns: TableHeaders[] = PerdiemHeaders;

  get isLastPage(): boolean {
    return !this.accountingModule.meta.lastKey;
  }

  constructor() {
    super();
  }

  get rows() {
    const a = [];
    for (let index = 0; index < 100; index++) {
      a.push({
        category: `caategory${index}`,
        date: '20210428000000',
        orderNumber: `aaaa${index}`,
        billTo: `bill to ${index}`,
        containerNumber: `number124 ${index}`
      });
    }
    return a;
  }
}
