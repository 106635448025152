

























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Fragment } from 'vue-fragment';
import { FilterInput } from '@/shared/services/filter/filter.service';
import {
  convertAPIToDateFormat,
  convertDateFieldToAPIFormatWithoutTime
} from '@/utils/date.util';

@Component({
  components: { Fragment }
})
export default class PeriodsFilter extends Vue {
  @Prop() toFilter: FilterInput<string>;
  @Prop() fromFilter: FilterInput<string>;

  to = null;
  from = null;

  @Watch('fromFilter')
  onFromFilterChange() {
    this.from = convertAPIToDateFormat(this.fromFilter.search);
  }
  @Watch('toFilter')
  onToFilterChange() {
    this.to = convertAPIToDateFormat(this.toFilter.search);
  }

  convertDateFieldToAPIFormatWithoutTime = convertDateFieldToAPIFormatWithoutTime;

  maxDate = null;

  created() {
    this.to = convertAPIToDateFormat(this.toFilter.search);
    this.from = convertAPIToDateFormat(this.fromFilter.search);
  }
}
