import {
  TableHeaders,
  CellContentType
} from '@/shared/components/table/models';

export const ScheduleHeaders: TableHeaders[] = [
  {
    key: 'category',
    label: `Category`,
    sortable: true
  },
  {
    key: 'date',
    label: `Date`,
    sortable: true,
    disabled: true
  },
  {
    key: 'orderNumber',
    label: `WO #`,
    sortable: true
  },
  {
    key: 'billTo',
    label: `Bill To`,
    sortable: true
  },
  {
    key: 'containerNumber',
    label: `CONT #`,
    sortable: true
  },
  {
    key: 'shippingLine',
    label: `SSL`,
    sortable: true
  },
  {
    key: 'containerSize',
    label: `SZ`,
    sortable: true
  },
  {
    key: 'chassisNumber',
    label: `Chassis #`,
    sortable: true
  },
  {
    key: 'chassisPool',
    label: `POOL`,
    sortable: true
  },
  {
    key: 'bond',
    label: `Bond`,
    sortable: true
  },
  {
    key: 'bondEntry',
    label: `Bond-Entry #`,
    sortable: true
  },
  {
    key: 'masterBillOfLading',
    label: `MBL / BOOK`,
    sortable: true
  },
  {
    key: 'referenceNumber',
    label: `REF`,
    sortable: true
  },
  {
    key: 'poNumber',
    label: `PO`,
    sortable: true
  },
  {
    key: 'weight',
    label: `WGT`,
    sortable: true
  },
  {
    key: 'quantity',
    label: `QTY`,
    sortable: true
  },
  {
    key: 'overHeight',
    label: `OH`,
    sortable: true
  },
  {
    key: 'overWeight',
    label: `OW`,
    sortable: true
  },
  {
    key: 'haz',
    label: `HAZ`,
    sortable: true
  },
  {
    key: 'hot',
    label: `HOT`,
    sortable: true
  },
  {
    key: 'pullOutLoaction',
    label: `P/OUT`,
    sortable: true
  },
  {
    key: 'deliveryLocation',
    label: `DILV`,
    sortable: true
  },
  {
    key: 'returnLocation',
    label: `RTN`,
    sortable: true
  },
  {
    key: 'eta',
    label: `ETA`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'lastFreeDay',
    label: `LFD`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'avaDscDate',
    label: `DSC`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'avaOblDate',
    label: `OBL`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'avaCusDate',
    label: `CUS`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'avaOthDate',
    label: `OTH`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'avaDate',
    label: `AVD`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'streetTurn',
    label: `S/T`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Time
    }
  },
  {
    key: 'returnFreeDay',
    label: `RFD`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'cfm',
    label: `CFM`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'pullOutSchedule',
    label: `P/OUT SCHE`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'deliverySchedule',
    label: `DILV SCHE`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'podPol',
    label: `POD/POL`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'demurrage',
    label: `Demurrage`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'waiting',
    label: `Waiting`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'perdiem',
    label: `PERDIEM`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'startLocation',
    label: `Start LOC ID`,
    sortable: true
  },
  {
    key: 'endLocation',
    label: `End LOC ID`,
    sortable: true
  },
  {
    key: 'startLocationName',
    label: `S IN`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'actualIn',
    label: `A IN`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'actualOut',
    label: `A OUT`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'driverId',
    label: `DRV`,
    sortable: true
  },
  {
    key: 'vehicle',
    label: `Vehicle`,
    sortable: true
  },
  {
    key: 'mile',
    label: `Mile`,
    sortable: true
  },
  {
    key: 'startTime',
    label: `Start`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Time
    }
  },
  {
    key: 'endTime',
    label: `End`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Time
    }
  },
  {
    key: 'diffTime',
    label: `Diff`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Time
    }
  },
  {
    key: 'updatedBy',
    label: `Updated By`,
    sortable: true
  },
  {
    key: 'memo',
    label: `Memo`,
    sortable: true,
    formatter: v => (v ? 'Y' : 'N')
  }
];
