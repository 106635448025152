









import { Component, Vue, Watch } from 'vue-property-decorator';

import { AccountingTypes } from './models/accounting.model';
import QbArAp from './QBARAP/QbArAp.vue';
import Chassis from './Chassis/Chassis.vue';
import Perdiem from './Perdiem/Perdiem.vue';
import Schedule from './Schedule/Schedule.vue';
import ApAr from './APAR/ApAr.vue';

@Component({
  components: {
    QBARAP: QbArAp,
    CHASSIS: Chassis,
    PERDIEM: Perdiem,
    SCHEDULE: Schedule,
    APAR: ApAr
  }
})
export default class Accounting extends Vue {
  @Watch('$route', { immediate: true })
  onRouterChange() {
    const type = this.$route.params.type.toUpperCase();
    this.selectedAccountingType = AccountingTypes[type];
    // this.setSelectedAccountingType(type);

    this.acctName = this.accChunks.find(item => item.type === type).name;
  }

  acctName = '';
  accChunks = [
    { type: AccountingTypes.QBARAP, name: 'QB AR/AP' },
    { type: AccountingTypes.CHASSIS, name: 'CHASSIS RECON' },
    { type: AccountingTypes.PERDIEM, name: 'PERDIEM RECON' },
    { type: AccountingTypes.SCHEDULE, name: 'SCHEDULE' },
    { type: AccountingTypes.APAR, name: 'AP/AR' }
  ];

  selectedAccountingType = '';

  constructor() {
    super();
  }

  setSelectedAccountingType(type) {
    switch (type) {
      case AccountingTypes.APAR:
        this.selectedAccountingType = type;
        break;
    }
  }
}
