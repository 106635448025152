
















import { Component, Vue } from 'vue-property-decorator';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { TableHeaders } from '@/shared/components/table/models';

import { AccountingModule } from '@/store/index';
import { AccountingTypes } from '../models/accounting.model';
import ApArFilters from './ApArFilters/ApArFilters.vue';
import { ApArHeaders } from './models/AparHeaders';
import { ToastHelper } from '@/utils/toast.util';

@Component({
  components: { TmsTable, ApArFilters }
})
export default class ApAr extends Vue {
  accountingModule = AccountingModule;

  topHeaders = [
    {
      key: 'eta',
      label: 'ETA',
      opportunityСhange: false,
      maxColspan: 3,
      minColspan: 1
    }
  ];

  columns: TableHeaders[] = ApArHeaders;

  get isLastPage(): boolean {
    return !this.accountingModule.meta.lastKey;
  }

  constructor() {
    super();
  }
}
