














import { Component, Vue, Prop } from 'vue-property-decorator';
import { Fragment } from 'vue-fragment';
import { FilterInput } from '@/shared/services/filter/filter.service';
@Component({
  components: { Fragment }
})
export default class TypeFilter extends Vue {
  @Prop() type: FilterInput<string>;

  typesList = [
    { value: null, text: 'All' },
    { value: 'AP', text: 'AP' },
    { value: 'AR', text: 'AR' }
  ];

  created() {
    this.type.search = null;
  }
}
