import {
  TableHeaders,
  CellContentType
} from '@/shared/components/table/models';

export const ApArHeaders: TableHeaders[] = [
  {
    key: 'type',
    label: `Category`,
    sortable: true
  },
  {
    key: 'date',
    label: `Date`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'orderNumber',
    label: `WO #`,
    sortable: true
  },
  {
    key: 'billTo',
    label: `Bill To`,
    sortable: true
  },
  {
    key: 'containerNumber',
    label: `CONT #`,
    sortable: true
  },
  {
    key: 'shippingLine',
    label: `SSL`,
    sortable: true
  },
  {
    key: 'containerSize',
    label: `SZ`,
    sortable: true
  },
  {
    key: 'chassisNumber',
    label: `Chassis #`,
    sortable: true
  },
  {
    key: 'chassisPool',
    label: `POOL`,
    sortable: true
  },
  {
    key: 'masterBillOfLading',
    label: `MBL / BOOK`,
    sortable: true
  },
  {
    key: 'referenceNumber',
    label: `REF`,
    sortable: true
  },
  {
    key: 'poNumber',
    label: `PO`,
    sortable: true
  },
  {
    key: 'deliveryLocation',
    label: `DILV LOC`,
    sortable: true
  },
  {
    key: 'accountDate',
    label: `ACCT Date`,
    sortable: true
  },
  {
    key: 'accountId',
    label: `ACCT ID`,
    sortable: true
  },
  {
    key: 'accountCode',
    label: `ACCT Code`,
    sortable: true
  },
  {
    key: 'accountName',
    label: `ACCT Code Name`,
    sortable: true
  },
  {
    key: 'amount',
    label: `AMT`,
    sortable: true
  },
  {
    key: 'transactionDate',
    label: `Transaction Date`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'transactionNumber',
    label: `Transaction #`,
    sortable: true
  }
];
