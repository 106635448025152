















import { Component, Vue } from 'vue-property-decorator';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { TableHeaders } from '@/shared/components/table/models';

import { AccountingModule } from '@/store/index';
import { AccountingTypes } from '../models/accounting.model';

import ScheduleFilters from './ScheduleFilters/ScheduleFilters.vue';
import { ScheduleHeaders } from './models/ScheduleHeaders';
import { ToastHelper } from '@/utils/toast.util';
import { convertAPIToViewFullFormat } from '@/utils/date.util';

@Component({
  components: { TmsTable, ScheduleFilters }
})
export default class Schedule extends Vue {
  accountingModule = AccountingModule;
  columns: TableHeaders[] = ScheduleHeaders;

  get tableData() {
    return this.accountingModule.accountingData.map(i => ({
      ...i,
      date: convertAPIToViewFullFormat(i.date)
    }));
  }

  get isLastPage(): boolean {
    return !this.accountingModule.meta.lastKey;
  }

  constructor() {
    super();
  }
}
